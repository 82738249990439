<template>
  <div>
    <v-autocomplete
      :id="id"
      ref="selectElement"
      v-model="internalValue"
      :disabled="disabled"
      class="select-base"
      :class="computedClasses"
      dense
      outlined
      append-icon="mdi-chevron-down"
      hide-no-data
      hide-details="auto"
      :label="label"
      :placeholder="placeholderVal"
      :items="items"
      item-text="itemTitle"
      :item-value="itemValue"
    >
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          v-bind="attrs"
          :id="'id' + attrs.id"
          #default="{ active }"
          class="item-list select-item"
          @click="onSelectItem(item, attrs)"
          @change="onChange"
          v-on="on"
        >
          <v-list-item-content :id="attrs.id">
            <v-list-item-title v-if="item.itemTitle">
              <v-tooltip v-if="item.itemTitle.length > 28" bottom :max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ start_and_end(item.itemTitle, 28) }}
                  </span>
                </template>
                {{ item.itemTitle }}
              </v-tooltip>
              <span v-else> {{ item.itemTitle }}</span>
            </v-list-item-title>

            <v-list-item-subtitle v-if="item.itemSubtitle" class="select-item-subtitle">
              {{ item.itemSubtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'SelectSimple',
  props: {
    id: {
      type: String,
      default: uniqueId('_select_simple'),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
      default: 'Select',
    },

    items: {
      type: Array,
      required: false,
      default: () => {
        return [{}];
      },
    },

    placeholder: {
      required: false,
      default: 'Selecione uma opção',
    },

    value: {
      type: [Boolean, String, Number, Array, Object],
      default: null,
    },

    itemValue: {
      type: String,
      default: 'itemId',
    },
  },

  data() {
    return {
      internalValue: this.value,
      placeholderVal: this.placeholder,
    };
  },

  computed: {
    computedClasses() {
      return this.disabled ? 'select-disabled' : 'select-active';
    },
  },

  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },

    internalValue(newValue) {
      this.$emit('input', newValue);
    },
  },

  methods: {
    checkSelected(itemsSelected) {
      this.clearPlaceholder();
      return itemsSelected.itemTitle;
    },

    clearPlaceholder() {
      const selectElement = this.$refs.selectElement;
      if (selectElement) {
        this.placeholderVal = this.placeholder;
      }
    },

    start_and_end(str, lengthStr) {
      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },

    onSelectItem(item, value) {
      this.$nextTick(() => {
        var valueInputed = !value.inputValue;
        this.$emit('input', this.internalValue);
        this.$emit('onSelect', { item: item, checked: valueInputed });
      });
    },

    onChange() {
      this.$emit('change', this.internalValue);
    },
  },
};
</script>

<style scoped lang="scss">
@import './src/design/variables.scss';
@import './src/design/components/input.scss';
@import './src/design/components/select.scss';
</style>
